<template>
  <div>
    <el-select v-model="value" :multiple="ismultiple" :allow-create="isAllow" filterable
      default-first-option :placeholder="placeholder" @change="handleChange" clearable>
      <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["options", "placeholder", "ismultiple", "isAllow"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    handleChange(val) {
      this.$emit("handleChange", val);
    },
    setValue(val) {
      this.value = val;
    },
    resetValue() {
      this.value = "";
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 200px;

  /deep/ .el-input__inner {
    border-radius: 0;
  }
}
</style>